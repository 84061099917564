
export const DefaultPinBoxHeight = 30;
export const DefaultPinBoxWidth = 40;

export default {
  name: 'MoleculePin',
  components: {},
  props: {
    pinData: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    expanded: {
      type: Boolean,
      required: false,
      default: false
    },
    onExpand: {
      type: Function,
      required: true,
      default: () => {}
    },
    onClose: {
      type: Function,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  computed: {
    pinStyle() {
      return {
        minWidth: `${DefaultPinBoxWidth}rem`,
        minHeight: `${DefaultPinBoxHeight}rem`
      };
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    clientManager() {
      return this.engine3d.default()
    },
    buildings() {
      return this.$store.state.project.project.buildings
    },
    selectedBuilding() {
      return this.$store.state.availability?.filters?.buildingId
    },
    buildingData() {
      return this.buildings.find((b) => b.id === this.selectedBuilding) || { code: 'main_view' }
    }
  },
  mounted() {},
  methods: {
    openExpand() {
      if (!this.expanded && !this.pinData.no_content && !this.pinData.featured_link) {
        this.onExpand()
      }
      if (this.pinData.featured_link) {
        window.open(this.pinData.featured_link, '_blank')
      }
    },
    closeExpand() {
      this.onClose()
    }
  }
}
