
import availabilityConstants from '~/store/availability/-constants'
import baseConstants from '~/store/base/-constants'

export default {
  name: 'MoleculeBuildingLabel',
  components: {},
  props: {
    labelData: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    clientManager() {
      return this.engine3d.getClientManager()
    },
    storeFilters() {
      return this.$store.state.availability.filters
    },
    includeLeasedSpacesInResultsList() {
      return this.$store.state.availability.includeLeasedSpaces
    },
    counterNumber() {
      if (this.includeLeasedSpacesInResultsList) {
        return this.labelData.totalSpaces || 0;
      }
      return this.labelData.availableSpaces
    },
    isLoadingData() {
      return this.$store.state.availability.isLoadingData
    },
    interactiveMouseEnabled() {
      return this.$store.state.base.interactiveMouseEnabled
    }
  },
  mounted() {
  },
  methods: {
    handleMouseEnter() {
      if (this.interactiveMouseEnabled) {
        this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.TOGGLE_INTERACTIVE_MOUSE_ENABLED), false)
      }
    },
    handleLabelClick() {
      if (this.storeFilters.buildingId === this.labelData.id) {
        return;
      }
      this.$store.dispatch(
        availabilityConstants.withNamespace(availabilityConstants.action.UPDATE_FILTERS),
        {
          buildingId: this.labelData.id,
          floorNo: undefined,
          floorId: undefined
        }
      )
    }
  }
}
