
import modalConstants from '~/store/modal/-constants'
import { WebRequest } from '~/helpers/api'
import requestOfferConstants from '~/store/requestOffer/-constants'
import MoleculeAvailabilitySpaceFilterResultCombinedSpace
  from "~/components/molecules/project/building/MoleculeAvailabilitySpaceFilterResultCombinedSpace.vue";

export const EnumInputTypes = {
  TEXT: 'text',
  DATE: 'date',
  TEXTAREA: 'textarea',
  NUMBER: 'number',
  PASSWORD: 'password',
  TEL: 'tel'
}
export default {
  name: 'MoleculeRequestOffer',
  components: {MoleculeAvailabilitySpaceFilterResultCombinedSpace},
  data() {
    return {
      errors: {},
      companyNumber: '',
      companyName: '',
      companyAddress: '',
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      notes: '',
      date: '',
      inputTypes: EnumInputTypes,
      buttonSendState: false
    }
  },
  computed: {
    buildings() {
      return this.$store.state.project.project.buildings
    },
    floors() {
      return this.$store.state.project.project.floors
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces
    },
    client() {
      return this.$config.CLIENT
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure === 1 ? 'sqft' : 'sqm'
    },
    showRequestOfferCalendarPicker() {
      const commonFeatures = this.$store?.state?.base?.meta?.generalConfig?.features?.common || {}
      if ('request_offer_show_calendar_picker' in commonFeatures) {
        return commonFeatures.request_offer_show_calendar_picker
      }
      return true
    },
    termsAndConditionsText() {
      if (this.$store?.state?.base?.meta?.generalConfig?.termsAndConditionsText) {
        return this.$store?.state?.base?.meta?.generalConfig?.termsAndConditionsText
      }
      return this.$t('agreeTerms')
    },
    surfaceMultiplier() {
      return this.$store.state?.project?.surfaceMultiplier || 1
    },
    surfaceName() {
      return this.$store.state?.project?.surfaceName || 1
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    projectFeatures() {
      return this.$store.getters?.featuresForProject(this.activeProject)
    },
    disableList() {
      return this.projectFeatures?.disableMyList
    },
    listSpaceDetails() {
      return this.projectFeatures?.listSpaceDetails || ['sqm', 'people']
    },
  },
  methods: {
    async submitForm(e) {
      e.preventDefault()

      this.buttonSendState = true

      if (this.validateInputs() !== null) return

      if (window && 'dataLayer' in window) {
        window.dataLayer.push({
          event: 'request_offer_submit',
          category: 'request_offer',
          label: 'request_offer'
        })
      }

      const selectedSpaces = this.requestedSpaces.map(({ id }) => {
        return { id }
      })

      const totalSqm = this.requestedSpaces.reduce((prev, next) => prev + next.sqm, 0)

      const payload = {
        company_number: this.companyNumber === '' ? null : this.companyNumber,
        company_name: this.companyName,
        company_address: this.companyAddress === '' ? null : this.companyAddress,
        contact_firstname: this.firstName,
        contact_lastname: this.lastName,
        contact_phone_number: this.phone,
        contact_email: this.email,
        notes: this.notes === '' ? null : this.notes,
        selected_spaces: selectedSpaces,
        status: 0,
        total_sqm: totalSqm
      }

      let remarks = null
      let proposedVisitDate = null
      const selector = 'input[type=radio]:checked'
      const checkedRadio = this.$el.querySelector(selector)
      if (checkedRadio) {
        const checkedRadioType = checkedRadio.parentNode.getAttribute('data-box')
        switch (checkedRadioType) {
          case 'no-visit':
            remarks = 'No visit date'
            payload.remarks = remarks
            break
          case 'interval': {
            remarks = `Visit date: ${
              checkedRadio.parentNode.parentNode
                .querySelector(".hide-select[data-tab='select-interval']")
                .querySelector(selector).value
            }`
            payload.remarks = remarks
            break
          }
          case 'date': {
            proposedVisitDate = this.date
            payload.proposed_visit_date = proposedVisitDate
            break
          }
        }
      }

      if (proposedVisitDate === null) {
        delete payload.proposed_visit_date
      }
      if (remarks === null) {
        delete payload.remarks
      }

      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'request_offer_conversion',
          payload
        })
      }

      const {
        data: { error: serverError, result }
      } = await WebRequest.POST(this.$webApi.landlord.requestOffer(), payload)
      if (serverError) {
        alert('Something wrong happened! Please try again!')
        return
      }
      if (this.$config.CLIENT === 'one' && this.$config.RUNTIME_ENV === 'production') {
        if ('gtag_report_conversion' in window) {
          window.gtag_report_conversion()
        }
        if ('lintrk' in window) {
          window.lintrk('track', { conversion_id: 4403868 })
        }
      }
      window.location.href = '/thank-you'
      setTimeout(function () {
        this.buttonSendState = false
        this.$refs['request-offer-form'].reset()
        if (this.disableList) {
          this.$store.dispatch(
            requestOfferConstants.withNamespace(requestOfferConstants.action.CLEAR_ALL)
          )
        }
        document.body.classList.remove('disable-scroll')
      }, 500)
    },
    validateInputs() {
      this.errors = {}
      if (this.companyName === '') {
        this.errors.companyName = this.$t('companyRequired')
      }
      if (this.firstName === '') {
        this.errors.firstName = this.$t('firstNameRequired')
      }
      if (this.lastName === '') {
        this.errors.lastName = this.$t('lastNameRequired')
      }
      if (this.phone === '' || !this.phone.match(/^[+]?\d{10,13}$/)) {
        this.errors.phone = this.$t('phoneRequired')
      }
      if (!this.validEmail(this.email)) {
        this.errors.email = this.$t('emailRequired')
      }
      if (!this.$refs.check.checked) {
        this.errors.agree = this.$t('agreeRequired')
      }
      if (Object.keys(this.errors).length === 0) {
        return null
      }
      this.buttonSendState = false
      return this.errors
    },
    validEmail: function (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },
    closeModal() {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'request_offer_cancel',
          payload: {}
        })
      }
      this.$store.dispatch(modalConstants.withNamespace(modalConstants.action.CLOSE_MODAL))
      if (this.disableList) {
        this.$store.dispatch(
          requestOfferConstants.withNamespace(requestOfferConstants.action.CLEAR_ALL)
        )
      }
      document.body.classList.remove('disable-scroll')
    },
    removeSpaceFromCart(spaceData, buildingId, floorId) {
      const space = {
        ...spaceData
      }
      space.building = this.buildings.find((b) => b.id === buildingId);
      space.floor = this.floors.find((f) => f.id === floorId);

      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'remove_space_from_list',
          payload: {
            zone: 'building',
            space
          }
        })
      }
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.REMOVE_SPACE),
        space
      )
      if (this.requestedSpaces.length === 0) {
        this.closeModal();
      }
    },
  }
}
