
import availabilityConstants from '~/store/availability/-constants'

export default {
  name: 'MoleculeBuildingFloorLabel',
  components: {},
  props: {
    labelData: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      isHovered: false
    }
  },
  computed: {
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    clientManager() {
      return this.engine3d.getClientManager()
    },
    storeFilters() {
      return this.$store.state.availability.filters
    },
    includeLeasedSpacesInResultsList() {
      return this.$store.state.availability.includeLeasedSpaces
    },
    counterNumber() {
      return this.labelData.availableSpaces
    },
    spaceDisplayData() {
      return this.$store.state.availability.space
    },
    hasSpaceResults() {
      return this.spaceDisplayData.length > 0
    },
    filteredBuildingData() {
      return this.$store.state.availability.project.find(
        (b) => b.id === this.storeFilters.buildingId
      )
    },
    filteredFloorData() {
      return this.$store.state.availability.building.find((f) => f.id === this.storeFilters.floorId)
    },
    isSelectedFloor() {
      return this.filteredFloorData?.id === this.labelData.id
    },
    selectedSpace() {
      return this.$store.state.building.space.spaceData
    },
    hasSelectedSpace() {
      return !!this.selectedSpace
    },
    isLoadingData() {
      return this.$store.state.availability.isLoadingData
    },
    isMenuOpen() {
      return this.$store.state.base.isAvailabilityMenuOpen
    },
  },
  mounted() {},
  methods: {
    handleMouseIn(e) {
      e.preventDefault();
      e.stopPropagation();

      this.clientManager.toggleFloorHover(`${this.labelData.building.code}_${this.labelData.code}`, true)
    },
    handleMouseOut(e) {
      e.preventDefault();
      e.stopPropagation();

      this.clientManager.toggleFloorHover(`${this.labelData.building.code}_${this.labelData.code}`, false)
    },
    setIsHovered(status) {
      this.isHovered = status;
    },
    handleLabelClick() {
      if (this.storeFilters.floorId === this.labelData.id && this.filteredBuildingData) {
        return
      }
      this.$store.dispatch(
        availabilityConstants.withNamespace(availabilityConstants.action.UPDATE_FILTERS),
        {
          buildingId: this.labelData.building_id,
          floorNo: this.labelData.no,
          floorId: this.labelData.id
        }
      )
    }
  }
}
