
import modalConstants from '~/store/modal/-constants'
import { WebRequest } from '~/helpers/api'

export const EnumInputTypes = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  NUMBER: 'number',
  PASSWORD: 'password',
  TEL: 'tel'
}
export default {
  name: 'MoleculeDownloadBrochure',
  data() {
    return {
      errors: {},
      name: '',
      phone: '',
      email: '',
      inputTypes: EnumInputTypes,
      buttonSendState: false
    }
  },
  computed: {
    termsAndConditionsText() {
      if (this.$store?.state?.base?.meta?.generalConfig?.termsAndConditionsText) {
        return this.$store?.state?.base?.meta?.generalConfig?.termsAndConditionsText
      }
      return this.$t('agreeTerms')
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    modalData() {
      return this.$store.state.modal.data
    }
  },
  methods: {
    async submitForm(e) {
      e.preventDefault()
      this.buttonSendState = true
      if (this.validateInputs() !== null) return
      if (window && 'dataLayer' in window) {
        window.dataLayer.push({
          event: 'download_brochure_submit',
          category: 'download_brochure',
          label: 'download_brochure'
        })
      }
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'download_brochure_submit',
          payload: {
            zone: 'download_brochure'
          }
        })
      }
      const payload = {
        name: this.name,
        phone: this.phone,
        email: this.email,
        ...(typeof this.modalData?.projectName !== 'undefined' && {
          projectName: this.$store.state.modal.data.projectName
        }),
        ...(typeof this.modalData?.brochureUrl !== 'undefined' && {
          brochureUrl: this.$store.state.modal.data.brochureUrl
        })
      }
      const {
        data: { error: serverError }
      } = await WebRequest.POST(this.$webApi.landlord.downloadBrochure(), payload)
      if (serverError) {
        alert('Something wrong happened! Please try again!')
        return
      }
      window.location.href = '/thank-you-brochure'
      setTimeout(function () {
        this.buttonSendState = false
        this.$refs['download-brochure-form'].reset()
        this.$store.dispatch(modalConstants.withNamespace(modalConstants.action.CLOSE_MODAL))
        document.body.classList.remove('disable-scroll')
      }, 500)
    },
    validateInputs() {
      this.errors = {}
      if (this.name === '') {
        this.errors.name = this.$t('nameRequired')
      }
      if (this.phone === '' || !this.phone.match(/^[+]?\d{10,13}$/)) {
        this.errors.phone = this.$t('phoneRequired')
      }
      if (!this.validEmail(this.email)) {
        this.errors.email = this.$t('emailRequired')
      }
      if (!this.$refs.check.checked) {
        this.errors.agree = this.$t('agreeRequired')
      }
      if (Object.keys(this.errors).length === 0) {
        return null
      }
      this.buttonSendState = false
      return this.errors
    },
    validEmail: function (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },
    closeModal() {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'download_brochure_cancel',
          payload: {}
        })
      }
      this.$store.dispatch(modalConstants.withNamespace(modalConstants.action.CLOSE_MODAL))
      document.body.classList.remove('disable-scroll')
    }
  }
}
